import clsx from "clsx"

import createStyle from "lib/createStyle"

export const themes = {
  primary: clsx(
    "bg-offblack text-cream border border-offblack hover:bg-[#2F2F2F]]"
  ),
  secondary: clsx(
    "bg-transparent text-offblack border border-[rgba(22,22,22,0.24)]"
  ),
  secondary_outline: clsx(
    "bg-transparent text-white border border-[#A8A8A8] border-opacity-20"
  ),
  disabled: clsx("bg-[#C6C6C1] text-[#8A8A85] cursor-not-allowed"),
  tertiary: clsx("bg-transparent underline"),
  project: clsx("bg-transparent no-underline w-full"),
  white: clsx("bg-[#FFFFF4] hover:bg-[#EEEBD7] text-offblack "),
  gold: clsx("bg-gold text-offblack hover:bg-[#C3B074]"),
  rodeo: clsx("bg-rodeo text-offblack"),
  ghost: clsx(
    "bg-transparent border border-[#1616163D] text-current hover:border-[#161616]"
  ),
  destructive: clsx("bg-red text-cream hover:bg-[#D51717]"),
  pea: clsx("bg-pea text-cream"),
  noSubmit: clsx("bg-pea text-[#fffff430] cursor-not-allowed"),
  back: clsx(
    "!absolute bottom-[58px] p-6 h-[76px] max-w-[168px] !font-[600] leading-[20px] mb-[48px] disabled:text-white w-full uppercase disabled:text-opacity-20 bg-white disabled:bg-transparent text-offblack transition duration-300"
  ),
  draft: clsx("bg-[#FA7B5F] text-black font-semibold hover:bg-[#FA7B5F]"),
  custom: "",
}

export const sizes = {
  s: "text-[12px] leading-[1.15] tracking-[0.01em] py-[8px] px-[16px] rounded-[12px]",
  m: "text-[14px] leading-[1.15] tracking-[0.01em] py-[10px] px-[20px] rounded-[12px]",
  l: "text-[14px] leading-[1.15] tracking-[0.01em] py-[12px] px-[24px] rounded-[12px] font-normal",
  xl: "text-[16px] leading-[24px] tracking-[0.01em] py-[12px] px-[28px] rounded-[12px]",
  xl2: "text-[16px] leading-[1.15] tracking-[0.01em] py-[20px] px-[40px] rounded-[20px]",
  large:
    "text-[16px] leading-[1.15] tracking-[0.01em] py-4 px-5 min-w-[140px] font-normal rounded-[12px]",
  action:
    "text-[16px] leading-[1.15] tracking-[0.01em] py-2 px-3 rounded-[12px]",
  custom: "",
}

export const ButtonBase = createStyle(
  "a",
  "inline-flex items-center first-letter:uppercase  transition duration-150 relative group text-center disabled:text-[#8A8A85] disabled:bg-[#C6C6C1] disabled:cursor-not-allowed disabled:border-[#C6C6C1] disabled:bg-transparent disabled:border-opacity-20 "
)
