"use client"

import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from "react"
import Cookies from "js-cookie"

import Toast from "components/Toast"
import { ToastProps } from "components/Toast/types"

export const ToastContext = createContext({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  addToast: (
    body?: ToastProps["body"],
    type?: ToastProps["type"],
    html?: ReactNode
  ) => {},
  /* eslint-enable @typescript-eslint/no-unused-vars */
})

export const TOAST_COOKIE_KEY = "showToast"

export const ToastContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [toasts, setToasts] = useState<({ timestamp: number } & ToastProps)[]>(
    []
  )
  const addToast = useCallback(
    (
      body?: string,
      // eslint-disable-next-line default-param-last
      type: ToastProps["type"] = "success",
      html?: ToastProps["html"]
    ) => {
      setToasts((t) => [...t, { body, type, html, timestamp: Date.now() }])
    },
    []
  )
  const deferredToasts = useDeferredValue(toasts)

  useEffect(() => {
    if (!Cookies.get(TOAST_COOKIE_KEY)) {
      return
    }
    addToast(Cookies.get(TOAST_COOKIE_KEY) as string)
    Cookies.remove(TOAST_COOKIE_KEY)
  }, [addToast])

  const fields = useMemo(() => ({ addToast }), [addToast])

  return (
    <ToastContext.Provider value={fields}>
      {children}
      {toasts.length > 0 && (
        <div className="fixed top-12 right-0 w-auto w-min-[350px] px-6 z-[99]">
          {deferredToasts.map(({ timestamp, ...toast }, idx) => (
            <Toast
              key={[toast.type, timestamp].join("-")}
              {...toast}
              className="mb-5 last:mb-0"
              close={() => setToasts((t) => t.filter((v, idz) => idx !== idz))}
            />
          ))}
        </div>
      )}
    </ToastContext.Provider>
  )
}
export default ToastContext
