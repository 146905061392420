"use client"

import { Chat, Streami18n } from "stream-chat-react"
import client, { StreamTypes } from "@/lib/chatClient"
import { FC, ReactNode } from "react"

const i18nInstance = new Streami18n({
  language: "en",
  translationsForLanguage: {
    "You have no channels currently":
      "You have no new messages. You can start a thread by messaging or expressing interest in a profile or project.",
    "Drag your files here": "Drag your files here. Max size 100MB",
  },
})

export const ChatContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <Chat<StreamTypes>
      customClasses={{
        messageList:
          "h-0 flex-grow overflow-hidden overflow-y-auto flex flex-col",
        virtualizedMessageList:
          "h-0 flex-grow overflow-hidden overflow-y-auto flex-col",
        chat: "flex",
        channel: "flex-grow",
        chatContainer: "bg-white flex flex-grow",
      }}
      client={client}
      i18nInstance={i18nInstance}
    >
      {children}
    </Chat>
  )
}
export default ChatContextProvider
